<template>
  <headBackBar :iCustomizdde="true" :icon="true">
    <template v-slot:left-icon>
      <van-icon name="arrow-left" @click="$router.go(-1)" />
    </template>
    <template #default>
      <span>
        {{ $fanyi("支付") }}
      </span>
    </template>
    <!--设置内容选项区域 内容区域 -->
  </headBackBar>
  <div class="Content" v-if="step == 1">
    <div class="top-box">
      <div class="title">
        {{ $fanyi("待支付金额") }}
      </div>
      <div class="price">
        {{ $fun.JPYNumSegmentation($route.query?.money) }}
        <span>{{ $fanyi("円") }}</span>
      </div>
      <p>{{ $fanyi("您正在申请收费会员天数，支付后实时生效") }}</p>
    </div>
    <!-- 金额 -->
    <div class="blance-box">
      <div>
        <div class="left">{{ $fanyi("账户当前可用余额") }}</div>
        <div class="right">
          {{
            $fun.JPYNumSegmentation($store.state.userInfo.balance_availability)
          }}<span>{{ $fanyi(" 円") }}</span>
        </div>
      </div>
      <div>
        <div class="left">{{ $fanyi("付款后剩余") }}</div>
        <div class="right">
          {{
            $fun.JPYNumSegmentation(
              $store.state.userInfo.balance_availability - $route.query.money
            )
          }}<span>{{ $fanyi(" 円") }}</span>
        </div>
      </div>
    </div>
    <!-- 确认付款 -->
    <div class="payment" @click="paymeny">{{ $fanyi("确认付款") }}</div>
  </div>

  <div class="main" v-if="step == 2">
    <div>
      <div class="paymentSuccessBox">
        <img
          class="successIcon"
          :src="require('@/assets/icon/chenggong.png')"
          alt=""
        />
        <p class="successFont">
          {{ $fanyi("支付成功") }}
        </p>
        <!-- 价格 -->
        <div class="price">
          {{ $fun.JPYNumSegmentation(pay) }}
          <span>{{ $fanyi("円") }}</span>
        </div>

        <div class="transactionNumber">
          <p>
            <span class="title"> {{ $fanyi("交易流水号") }}: </span>
            <span class="con">{{ transactionnumber }}</span>
          </p>
        </div>

        <button
          class="operationButton red"
          @click="$fun.routerToPage('/user/index')"
        >
          {{ $fanyi("回到个人中心") }}
        </button>
        <button class="operationButton" @click="$fun.routerToPage('/')">
          {{ $fanyi("回到首页") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script setup>
import dayjs from "dayjs";
import headBackBar from "../../../../components/headGoBack/index.vue";
import { getCurrentInstance, ref } from "vue";
import { Toast } from "vant";
const { proxy } = getCurrentInstance();

const step = ref(1);
// 充值金额
const pay = ref(0);
// 交易号
const transactionnumber = ref(null);
const paymeny = async () => {
  if (
    proxy.$store.state.userInfo.balance_availability -
      proxy.$route.query.money <
    0
  ) {
    Toast(proxy.$fanyi("余额不足，请先充值"));
    return;
  }
  Toast.loading({
    duration: 0,
    message: proxy.$fanyi("加载中..."),
    forbidClick: true,
  });
  const res = await proxy.$api.balancePayVip({
    days: proxy.$route.query.days,
  });
  if (res.code !== 0) {
    Toast.fail(proxy.$fanyi(res.msg));
    Toast.clear();
    return;
  }
  Toast.clear();
  pay.value = res.data.pay;
  step.value = 2;
  transactionnumber.value = res.data.serial_number;
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.Content {
  padding-top: 30px;
  width: 750px;
  min-height: 1400px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 150px;
  background: #f6f6f6;

  .top-box {
    width: 690px;
    height: 276px;
    background: #ffffff;
    border-radius: 6px;
    padding-top: 60px;

    .title {
      width: 100%;
      margin-bottom: 29px;
      display: flex;
      justify-content: center;
      font-size: 24px;

      font-weight: 400;
      color: #999999;
    }

    .price {
      width: 100%;
      display: flex;
      justify-content: center;
      font-size: 56px;

      font-weight: 600;
      color: #b4272b;
      margin-bottom: 29px;

      span {
        font-size: 24px;

        font-weight: 400;
        padding-top: 25px;
        padding-left: 3px;

        color: #b4272b;
      }
    }

    p {
      width: 100%;
      text-align: center;
      font-size: 24px;

      font-weight: 400;
      color: #000000;
    }

    margin-bottom: 15px;
  }

  .blance-box {
    width: 690px;
    height: 190px;
    background: #ffffff;
    border-radius: 6px;
    padding: 42px 30px;
    display: flex;
    flex-direction: column;
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:first-child {
        margin-bottom: 47px;
      }
      .left {
        font-size: 28px;
        font-weight: 400;
        color: #999999;
      }

      .right {
        font-size: 28px;

        font-weight: 600;
        color: #b4272b;

        span {
          font-size: 28px;
          color: #000000;
          margin-left: 10px;
        }
      }
    }
  }

  .payment {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 50px;
    width: 690px;
    height: 70px;
    background: #b4272b;
    border-radius: 6px;
    font-size: 28px;

    font-weight: 400;
    color: #ffffff;
  }
}

.main {
  padding-top: 30px;
  width: 750px;
  min-height: 1400px;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  background: #f6f6f6;

  .paymentSuccessBox {
    width: 690px;
    background: #ffffff;
    border-radius: 6px;
    padding: 60px 30px;
    height: 1400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px auto 0;

    .successIcon {
      width: 80px;
      height: 80px;
      margin-bottom: 41px;
    }

    .successFont {
      font-size: 32px;
      color: #000000;
      line-height: 50px;
      text-align: center;
      p {
        font-size: 27px;
        line-height: 35px;
        text-align: left;
        &:first-child {
          font-size: 32px;
          margin-bottom: 15px;
          font-weight: bold;
          text-align: center;
        }
      }
    }

    .price {
      width: 100%;
      text-align: center;
      font-size: 56px;

      font-weight: 600;
      color: #b4272b;
      margin-top: 30px;
      margin-bottom: 107px;

      span {
        font-size: 24px;

        font-weight: 400;
        color: #b4272b;
      }
    }

    .transactionNumber {
      width: 100%;
      margin-bottom: 240px;
      display: flex;
      justify-content: center;

      p {
        min-width: 100px;

        display: flex;

        align-items: center;

        &:last-child {
          margin-bottom: 0;
        }

        .title {
          font-size: 28px;
          color: #000000;

          text-align: left;
        }

        .con {
          margin-left: 28px;

          font-size: 28px;
          color: #000000;
        }
      }
    }

    .operationButton {
      padding: 0 50px;
      height: 70px;
      background: #ffffff;
      border: 1px solid #dfdfdf;
      border-radius: 6px;
      font-size: 28px;
      width: 100%;
      margin-top: 20px;
      color: #000000;
    }

    .operationButton.red {
      background: #b4272b;
      color: #ffffff;
    }
  }
}
</style>
